<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M23.873,6.206c-0.094-0.094-0.221-0.147-0.354-0.147l-2.525-0.004V3.497c0-0.276-0.224-0.5-0.5-0.5H18.05
      l0.131-0.996c0.018-0.136-0.021-0.273-0.107-0.379s-0.212-0.173-0.349-0.184L3.496,0.299C3.358,0.291,3.229,0.332,3.127,0.42
      s-0.163,0.214-0.17,0.349L2.829,2.997H0.494c-0.276,0-0.5,0.224-0.5,0.5v15c0,0.276,0.224,0.5,0.5,0.5h0.958l-0.248,1.988
      c-0.017,0.135,0.022,0.271,0.108,0.377c0.086,0.105,0.211,0.172,0.347,0.183l14.228,1.15c0.014,0.001,0.027,0.002,0.041,0.002
      c0.257,0,0.476-0.198,0.497-0.459l0.021-0.251h7.013c0.275,0,0.499-0.223,0.5-0.498L24.02,6.561
      C24.02,6.428,23.967,6.301,23.873,6.206z M3.926,1.336L17.12,2.393l-0.079,0.604H3.831L3.926,1.336z M0.994,3.997h2.25
      c0.01,0.001,0.018,0.007,0.028,0.007c0.015,0,0.029-0.006,0.044-0.007h14.085c0.005,0,0.008,0.003,0.012,0.004
      c0.022,0.003,0.044,0.004,0.066,0.004c0.016,0,0.03-0.007,0.045-0.008h2.469v14h-19v-14H0.994z M2.261,20.591l0.199-1.594h13.229
      l-0.218,2.661L2.261,20.591z M22.96,20.987h-6.432l0.163-1.99h3.803c0.276,0,0.5-0.224,0.5-0.5V7.054l2.022,0.004L22.96,20.987z"/>
    <path
      d="M9.998,8.982c-0.171,0-0.207,0-2.031,0.802L8.2,7.871c0.028-0.223-0.033-0.432-0.171-0.588
      C7.77,6.99,7.356,6.99,7.054,6.99c-1.982,0-3.658,1.486-3.898,3.456c-0.136,1.115,0.214,2.239,0.961,3.082
      c0.746,0.842,1.819,1.325,2.945,1.325c1.982,0,3.658-1.485,3.897-3.456c0.037-0.297,0.08-1.613-0.396-2.151
      C10.412,9.076,10.211,8.982,9.998,8.982z M9.967,11.277c-0.179,1.469-1.428,2.577-2.905,2.577c-0.839,0-1.64-0.36-2.196-0.988
      c-0.557-0.629-0.819-1.467-0.718-2.298c0.18-1.47,1.429-2.578,2.906-2.578c0.034,0,0.078,0,0.123,0.001l-0.31,2.543
      c-0.022,0.179,0.054,0.355,0.198,0.462c0.145,0.109,0.336,0.128,0.5,0.056c1.007-0.444,1.966-0.865,2.305-1.015
      C9.958,10.321,10.011,10.919,9.967,11.277z M9.998,9.982H9.997l0.001-0.5V9.982z"/>
    <path
      d="M17.479,12.997h-3.971c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h3.971c0.276,0,0.5-0.224,0.5-0.5
      S17.755,12.997,17.479,12.997z"/>
    <path
      d="M17.479,9.997h-3.971c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h3.971c0.276,0,0.5-0.224,0.5-0.5
      S17.755,9.997,17.479,9.997z"/>
    <path
      d="M17.479,6.996h-3.971c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h3.971c0.276,0,0.5-0.224,0.5-0.5
      S17.755,6.996,17.479,6.996z"/>
  </svg>
</template>
